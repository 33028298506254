import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import { GridLeft, GridRight, contentPadding, breakpoints } from 'src/utils/styles/global-styles'
import { FlagStyles } from '../Navigation/styles'

const klaviyoFormId = process.env.GATSBY_KLAVIYO_FORM_ID

export const Section = styled.div`
  background-color: ${(props) => props.theme.orange};
  padding: 65px auto 45px;
`
export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media (max-width: ${breakpoints.l}px) {
    justify-content: center;
  }
  @media (max-width: ${breakpoints.s}px) {
    width: 90%;
    margin: auto;
  }
`
export const Column = styled.div`
  position: relative;
  margin: 0px 20px;
  .flagShadow {
    ${FlagStyles}
  }
  @media (max-width: ${breakpoints.xl}px) {
    margin: 0px 7px;
  }
  @media (max-width: ${breakpoints.l}px) {
    margin: 0px 20px;
  }
  @media (max-width: ${breakpoints.s}px) {
    margin: ${(props) => (props.space ? '0px 20px 0px 0px' : '0px')};
  }
`
export const Logo = styled.h1`
  margin: 0px;
  font-size: 30px;
  color: ${(props) => props.theme.grayDark};
  @media (max-width: ${breakpoints.l}px) {
    width: 90vw;
    text-align: center;
    margin-bottom: 55px;
    font-size: 30px;
  }
  @media (max-width: ${breakpoints.s}px) {
    margin-bottom: 20px;
  }
`
const LinkStyles = css`
  color: ${(props) => props.theme.grayDark};
  text-decoration: none;
  &:hover {
    opacity: 0.75;
  }
`
export const FooterLink = styled(Link)`
  ${LinkStyles}
  display: block;
  font-family: 'FilsonPro';
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 49px;
  letter-spacing: 0.08px;
  text-align: left;
  margin: 0px;
  border-bottom: 1.5px solid ${(props) => props.theme.golden};
  min-width: 130px;
  @media (max-width: ${breakpoints.s}px) {
    min-width: 0px;
  }
`

const FlexColGridStyles = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1.7;
`

export const Container = styled.footer`
  ${contentPadding}
  position: relative;
  padding-top: 4rem;
  padding-bottom: 4rem;
  z-index: 10;
  max-width: 1366px;
  margin: auto;
  @media (max-width: ${breakpoints.m}px) {
    padding-bottom: ${(props) => (props.paddingBottom ? '280px' : '4rem')};
  }
  @media (max-width: ${breakpoints.s}px) {
    padding-bottom: ${(props) => (props.paddingBottom ? '250px' : '4rem')};
  }
  .country-dropdown-content {
    display: flex;
    position: absolute;
    width: 160px;
    flex-wrap: wrap;
    justify-content: space-around;
    background-color: ${(props) => [props.theme.cream]};
    left: 0;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    padding: 5px;
  }
  .dropdown-content a,
  .country-dropdown-content a {
    padding: 0px 7px;
    text-decoration: none;
    font-size: 20px;
  }
  @media (max-width: ${breakpoints.s}px) {
    padding: 40px 0px;
    .country-dropdown-content {
      width: 160px;
      margin-left: -50px;
      justify-content: space-between;
    }
  }
  @media (max-width: 450px) {
    .country-dropdown-content {
      width: 110px;
      margin-left: -20px;
      justify-content: space-between;
    }
  }
`
const FooterLinkExternalStyles = css`
  cursor: pointer;
  display: block;
  font-family: 'FilsonPro';
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 49px;
  letter-spacing: 0.08px;
  text-align: left;
  border-bottom: 1.5px solid ${(props) => props.theme.golden};
  outline: 0;
  a:active,
  a:focus,
  a:visited {
    outline: 0;
    border: none;
    -moz-outline-style: none;
  }
  @media (max-width: 340px) {
    font-size: 14px;
  }
`
export const FooterLinkExternal = styled.a`
  ${LinkStyles}
  ${FooterLinkExternalStyles}
  img {
    margin-top: 5px;
    margin-bottom: -5px;
  }
`

export const FlexGridLeft = styled(GridLeft)`
  ${FlexColGridStyles}
`
export const FlexGridRight = styled(GridRight)`
  ${FlexColGridStyles}
`

export const SocialLinksContainer = styled.div`
  display: flex;
  @media (max-width: ${breakpoints.l}px) {
    padding-top: 40px;
    justify-content: center;
  }
`
export const SocialLinks = styled.a`
  ${LinkStyles}
  display: block;
  font-family: 'FilsonPro';
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 49px;
  letter-spacing: 0.08px;
  text-align: left;
  @media (max-width: ${breakpoints.l}px) {
    svg {
      size: 30px;
    }
  }
  @media (max-width: 340px) {
    font-size: 14px;
  }
`
export const ShareLinks = styled.div`
  display: flex;
  justify-content: center;
`

export const Form = styled.div`
  max-width: 380px;
  input,
  button {
    font-family: 'FilsonPro' !important;
  }
  label {
    margin-bottom: 20px;
    font-family: 'FilsonPro' !important;
  }
  label > div {
    font-family: 'FilsonProBook' !important;
  }
  .klaviyo-form-${klaviyoFormId} {
    border-bottom: 1.5px solid ${(props) => props.theme.golden};
    input {
      font-family: 'FilsonPro' !important;
    }
    button {
      align-self: center !important;
      font-weight: 300 !important;
    }
  }
  .FormComponent__StyledFormComponentWrapper-e0xun6-0 {
    padding: 0px !important;
  }
  .needsclick span {
    margin: 0px !important;
    font-family: 'P22MackinacProBook' !important;
    font-weight: normal !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.83 !important;
    letter-spacing: 0.12px !important;
    text-align: left !important;
  }
  .needsclick #klaviyo_ariaid_2 {
    font-size: 14px !important;
    font-family: 'FilsonPro' !important;
  }
  @media (max-width: ${breakpoints.l}px) {
    max-width: 340px;
    margin: auto;
    .needsclick span {
      display: block;
      text-align: center !important;
      margin-top: 40px !important;
      line-height: 42px !important;
      letter-spacing: 0.11px !important;
    }
    .needsclick #klaviyo_ariaid_2 {
      font-size: 12px !important;
      margin-top: 0px !important;
      text-align: left !important;
      line-height: 1.75 !important;
    }
  }
  @media (max-width: ${breakpoints.l}px) {
    .needsclick span {
      margin-top: 0px !important;
    }
    label,
    label > div {
      font-size: 15px !important;
    }
  }
`
export const Contact = styled.p`
  font-family: 'FilsonPro';
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 26px;
  letter-spacing: 0.08px;
  text-align: left;
  a {
    color: ${(props) => props.theme.grayDark};
    text-decoration: none;
  }
  @media (max-width: ${breakpoints.m}px) {
    font-size: 13px;
  }
`

export const IconContainer = styled.div`
  font-size: 14px;
  margin-right: 20px;
  @media (max-width: ${breakpoints.l}px) {
    font-size: 18px;
  }
`
export const SocialMediaDesktopView = styled.div`
  display: block;
  padding-top: 10px;
  @media (max-width: ${breakpoints.l}px) {
    display: none;
  }
`
export const SocialMediaMobileView = styled.div`
  display: none;
  > div {
    width: 120px;
    margin: auto;
  }
  @media (max-width: ${breakpoints.l}px) {
    display: block;
    > div {
      width: 700px;
      margin: auto;
    }
  }
  @media (max-width: ${breakpoints.m}px) {
    > div {
      width: 300px;
      margin: auto;
    }
  }
`
export const FlagLink = styled.a`
  ${LinkStyles}
  font-size: 24px;
  border: 1 solid black;
`
