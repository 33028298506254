export const BUY_LINK = process.env.GATSBY_FRAME_BUY_LINK
export const FRAME_2_BUY_LINK = '/products/skylight-frame-2-wood-walnut'
export const FRAME_PRODUCT_HANDLE = process.env.GATSBY_FRAME_PRODUCT_HANDLE
export const PLUS_BUY_LINK = '/products/skylight-plus/'
export const PLUS_PRODUCT_HANDLE = 'skylight-plus'
export const CAL_BUY_LINK =
  process.env.GATSBY_I18N_DEFAULT_LANGUAGE === 'en-ca' ||
  process.env.GATSBY_I18N_DEFAULT_LANGUAGE === 'en-au' ||
  process.env.GATSBY_I18N_DEFAULT_LANGUAGE === 'en-gb'
    ? '/products/the-skylight-calendar-15/'
    : '/products/skylight-calendar/'

export const CAL_PLUS_BUY_LINK = '/products/calendar-skylight-plus/'

export const CAL_MAX_BUY_LINK = '/products/skylight-calendar-max/'
export const CAL_MAX_BLACK_BUY_LINK = '/products/skylight-calendar-max-classic-black/'
export const CAL_MAX_WHITE_BUY_LINK = '/products/skylight-calendar-max-classic-white/'
export const CAL_MAX_ALUMINUM_BUY_LINK =
  '/products/skylight-calendar-max-shadow-box-natural-aluminum/'
export const CAL_MAX_CHARCOAL_BUY_LINK = '/products/skylight-calendar-max-shadow-box-charcoal/'

export const FRAME_2_BLACK_BUY_LINK = '/products/skylight-frame-2-classic-black/'
export const FRAME_2_WHITE_BUY_LINK = '/products/skylight-frame-2-classic-white/'
export const FRAME_2_LAGOON_BUY_LINK = '/products/skylight-frame-2-classic-lagoon/'
export const FRAME_2_SAGE_BUY_LINK = '/products/skylight-frame-2-classic-sage/'
export const FRAME_2_WALNUT_BUY_LINK = '/products/skylight-frame-2-wood-walnut/'
export const FRAME_2_BIRCH_BUY_LINK = '/products/skylight-frame-2-wood-birch/'
export const FRAME_2_CHARCOAL_BUY_LINK = '/products/skylight-frame-2-shadow-box-charcoal/'
export const FRAME_2_ALUMINUM_BUY_LINK = '/products/skylight-frame-2-shadow-box-aluminum/'
export const FRAME_2_COVER_WHITE_BUY_LINK = '/products/skylight-frame-2-cover-white/'
export const FRAME_2_COVER_BLACK_BUY_LINK = '/products/skylight-frame-2-cover-black/'

export const INITIALIZE_AMPLITUDE = `!function(){"use strict";!function(e,t){var r=e.amplitude||{_q:[],_iq:[]};if(r.invoked)e.console&&console.error&&console.error("Amplitude snippet has been loaded.");else{var n=function(e,t){e.prototype[t]=function(){return this._q.push({name:t,args:Array.prototype.slice.call(arguments,0)}),this}},s=function(e,t,r){return function(n){e._q.push({name:t,args:Array.prototype.slice.call(r,0),resolve:n})}},o=function(e,t,r){e[t]=function(){if(r)return{promise:new Promise(s(e,t,Array.prototype.slice.call(arguments)))}}},i=function(e){for(var t=0;t<g.length;t++)o(e,g[t],!1);for(var r=0;r<m.length;r++)o(e,m[r],!0)};r.invoked=!0;var u=t.createElement("script");u.type="text/javascript",u.integrity="sha384-GHWzi7MsT/TD3t0f+KUaVeuvPUsuVgdUKegrAWlzO4I83+klmUJna8WTuUunlsg6",u.crossOrigin="anonymous",u.async=!0,u.src="https://cdn.amplitude.com/libs/analytics-browser-1.6.6-min.js.gz",u.onload=function(){e.amplitude.runQueuedFunctions||console.log("[Amplitude] Error: could not load SDK")};var a=t.getElementsByTagName("script")[0];a.parentNode.insertBefore(u,a);for(var c=function(){return this._q=[],this},l=["add","append","clearAll","prepend","set","setOnce","unset","preInsert","postInsert","remove","getUserProperties"],p=0;p<l.length;p++)n(c,l[p]);r.Identify=c;for(var d=function(){return this._q=[],this},v=["getEventProperties","setProductId","setQuantity","setPrice","setRevenue","setRevenueType","setEventProperties"],f=0;f<v.length;f++)n(d,v[f]);r.Revenue=d;var g=["getDeviceId","setDeviceId","getSessionId","setSessionId","getUserId","setUserId","setOptOut","setTransport","reset"],m=["init","add","remove","track","logEvent","identify","groupIdentify","setGroup","revenue","flush"];i(r),r.createInstance=function(){var e=r._iq.push({_q:[]})-1;return i(r._iq[e]),r._iq[e]},e.amplitude=r}}(window,document)}();

console.log('Gatsby: Initializing Amplitude')
amplitude.init("${process.env.GATSBY_AMPLITUDE_KEY}", null, {
   includeUtm: true,
   includeReferrer: true
 });`

export const SUPPORT_LINK = {
  en: 'https://skylight.zendesk.com/hc/en-us',
  'en-ca': 'https://skylight.zendesk.com/hc/en-us',
  'en-gb': 'https://skylight.zendesk.com/hc/en-us',
  'en-au': 'https://skylight.zendesk.com/hc/en-us',
  calendar: 'https://skylight.zendesk.com/hc/en-us/categories/360001535832-Skylight-Calendar',
}

export const CONTACT_US_LINK = {
  en: 'https://skylight.zendesk.com/hc/en-us/categories/360001842931-Contact-Us',
  'en-ca': 'https://skylight.zendesk.com/hc/en-us/requests/new',
  'en-gb': 'https://skylight.zendesk.com/hc/en-us/requests/new',
  'en-au': 'https://skylight.zendesk.com/hc/en-us/requests/new',
}

export const COUNTRIES = {
  'en-au': {
    country: 'Australia',
    link: 'https://au.skylightframe.com/',
    currency: 'AUD',
    abv: 'AU',
    localization: 'en-au',
    numberFormat: 'en-AU',
  },
  'en-ca': {
    country: 'Canada',
    link: 'https://ca.skylightframe.com/',
    currency: 'CAD',
    abv: 'CA',
    localization: 'en-ca',
    numberFormat: 'en-CA',
  },
  'en-gb': {
    country: 'United Kingdom',
    link: 'https://uk.skylightframe.com/',
    currency: 'GBP',
    abv: 'GB',
    localization: 'en-gb',
    numberFormat: 'en-GB',
  },
  en: {
    country: 'United States',
    link: 'https://www.skylightframe.com/',
    currency: 'USD',
    abv: 'US',
    localization: 'en',
    numberFormat: 'en-US',
  },
}

export const LOCALIZATION = process.env.GATSBY_I18N_DEFAULT_LANGUAGE || 'en'

export const IS_FOREIGN_COUNTRY = process.env.GATSBY_I18N_DEFAULT_LANGUAGE !== 'en'

export const IN_UK = process.env.GATSBY_I18N_DEFAULT_LANGUAGE === 'en-gb'

export const IN_AU = process.env.GATSBY_I18N_DEFAULT_LANGUAGE === 'en-au'

export const IN_CA = process.env.GATSBY_I18N_DEFAULT_LANGUAGE === 'en-ca'

export const SHOW_COOKIE_CONSENT_BANNER = IN_UK
export const ENGLISH_SPEAKING_COUNTRY = IN_UK || IN_AU || IN_CA || !IS_FOREIGN_COUNTRY

export const currencyCode = COUNTRIES[LOCALIZATION]?.currency

export const TIMEZONES = {
  'en-au': 'AEDT',
  'en-ca': 'EST',
  'en-gb': 'GMT',
}

export const RETURN_GUARANTEE_PRODUCT_HANDLE = '120-days-guarantee'
